<template>
  <BlockHero v-if="hero" v-editable="hero" :blok="hero" />

  <StoryblokComponent
    v-for="component in blok.content"
    :key="component._uid"
    v-editable="component"
    :blok="component"
  />

  <PhotoGallery
    v-if="communityContent?.photoGallery?.length"
    :is-open="isPhotoGalleryVisible"
    :images="communityContent.photoGallery"
    @close="onPhotoGalleryModalClose"
  >
    <template #title>
      <template
        v-for="displaySuite in communityContent.displaySuiteLocation"
        :key="displaySuite._uid"
      >
        {{ displaySuite.line1 }} {{ displaySuite.line2 }}
      </template>
    </template>

    <template #subTitle>
      <template
        v-for="displaySuite in communityContent.displaySuiteLocation"
        :key="displaySuite._uid"
      >
        {{ displaySuite.suburb }} {{ displaySuite.state?.toUpperCase() }}
        {{ displaySuite.postCode }}
      </template>
    </template>

    <template #headerActions>
      <div class="hidden lg:block">
        <PhotoGalleryContactButtons
          :book-inspection-button-link="
            communityContent?.bookInspectionButtonLink
          "
          :agent-phone="agentPhone"
          @close="onPhotoGalleryModalClose"
        />
      </div>
    </template>

    <template #footerActions>
      <PhotoGalleryContactButtons
        :book-inspection-button-link="
          communityContent?.bookInspectionButtonLink
        "
        :agent-phone="agentPhone"
        @close="onPhotoGalleryModalClose"
      />
    </template>
  </PhotoGallery>

  <VideoGallery
    v-if="blok.videoGallery?.length"
    :is-open="isVideoGalleryVisible"
    :videos="blok.videoGallery"
    @close="onVideoGalleryModalClose"
  />

  <PhotoGallery
    v-if="blok.aerialMap?.length"
    :is-open="isAerialMapVisible"
    :is-contained="true"
    :has-bottom-gap="true"
    :images="blok.aerialMap"
    @close="onAerialMapModalClose"
  />
</template>

<script setup lang="ts">
import type { ContentTypeCommunityStoryblok } from '@/types/storyblok'

const props = defineProps<{
  blok: ContentTypeCommunityStoryblok
}>()
const hero = computed(() => props.blok.hero?.[0])
const store = useUIStore()
const { communityContent } = storeToRefs(useCurrentStoryStore())
const agentPhone = computed(() => {
  const agent = findFirstAgentWithProp(communityContent.value?.agents, 'phone')
  return agent?.content.phone
})

const { isPhotoGalleryVisible, isVideoGalleryVisible, isAerialMapVisible } =
  storeToRefs(store)

function onPhotoGalleryModalClose(): void {
  store.togglePhotoGallery(false)
}

function onVideoGalleryModalClose(): void {
  store.toggleVideoGallery(false)
}

function onAerialMapModalClose(): void {
  store.toggleAerialMap(false)
}
</script>
